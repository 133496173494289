// @flow
import * as React from 'react'
import withHover from '../hoc/withHover'

const PrinterIcon = ({ style, ...rest }: Object) => (
  <svg
    viewBox="0 0 24 24"
    {...rest}
    width="1em"
    height="1em"
    style={{ cursor: 'pointer', ...style }}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      stroke="currentColor"
      fill="none"
      strokeMiterlimit={10}
    >
      <path data-color="color-2" d="M6 6V1h12v5" />
      <path d="M5 18H1v-7c0-2.8 2.2-5 5-5h12c2.8 0 5 2.2 5 5v7h-4M17 10h1" />
      <path data-color="color-2" d="M6 14h12v9H6z" />
    </g>
  </svg>
)

export default withHover<React.Config<Object, {}>>(PrinterIcon)
