// @flow
import * as React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import type { AreaTypes } from '../../types/enums/AreaTypes'

import PrinterIcon from '../../icons/PrinterIcon'
import BaseButton from '../../components/Button/BaseButton'
import { mediaQuery, mediaOnly } from '../../styles/media'
import { effects, color } from '../../styles/theme'

export type Props = {
  text?: string,
  area?: AreaTypes,
}

type State = {
  hover: boolean,
}

const StyledButton = styled(BaseButton)`
  display: ${({ theme }) =>
    theme.name === 'kids' || theme.name === 'lgbt'
      ? 'none'
      : 'flex'}; /* hide Breadcrumbs on kids/lgbt theme, quick'n'dirty */

  margin-top: -3px; /* To align with other Article top element */

  z-index: 1;
  position: absolute;
  right: 0;
  padding: 0 ${rem(30)};
  flex-direction: row;
  align-items: center;

  p {
    margin: 0 ${rem(24)} 0 0;
  }

  /* Hide on mobile */
  ${mediaOnly.xs`
    display: none;
  `}

  ${mediaQuery.sm`
    padding: 0 ${rem(100)};  
  `}

  @media print {
    display: none;
  }
`

class PrintButton extends React.Component<Props, State> {
  static displayName = 'PrintButton'
  static defaultProps = {}

  constructor(props: Props) {
    super(props)

    this.state = {
      hover: false,
    }
  }

  handleMouseEnter = () => {
    this.setState({ hover: true })
  }

  handleMouseLeave = () => {
    this.setState({ hover: false })
  }

  render() {
    const { text } = this.props
    const { hover } = this.state
    const { handleMouseEnter, handleMouseLeave } = this

    //This component is only used in the `main` theme so we can use the styling like this
    const style = {
      transition: effects().linkHoverTransition,
      ...{ color: hover ? color.accent : color.primary },
    }

    return (
      <StyledButton
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          if (window) {
            window.print()
          }
        }}
      >
        <p style={style}>{text}</p>
        <PrinterIcon style={style} />
      </StyledButton>
    )
  }
}

export default PrintButton
